export const getBaseUrl = (): string => {
    const urls: {[key: string]: string} = {
      'http://areadocliente.smartboxbrasil.com.br.s3-website-sa-east-1.amazonaws.com': 'https://cloud103.cd1dyndns.com:5147/',
      'http://areadocliente.smartboxbrasil.com.br': 'https://cloud103.cd1dyndns.com:5147/',
      'https://areadocliente.smartboxbrasil.com.br': 'https://cloud103.cd1dyndns.com:5147/',
      'https://d3h1e8vtyr87vq.cloudfront.net': 'https://cloud103.cd1dyndns.com:5147/',
      'http://areadocliente.youboxselfstorage.com.br.s3-website-sa-east-1.amazonaws.com': 'https://cloud103.cd1dyndns.com:5147/',
      'http://areadocliente.guardeperto.com.br.s3-website-sa-east-1.amazonaws.com': 'https://guardeperto.cd1dyndns.com:7000/',
      'http://areadocliente.weboxstorage.com.s3-website-sa-east-1.amazonaws.com': 'https://cloud103.cd1dyndns.com:5147/',
      'http://areadocliente.anexxo.com.br.s3-website-sa-east-1.amazonaws.com': 'https://cloud103.cd1dyndns.com:5147/',
      'https://areadocliente.weboxstorage.com': 'https://cloud103.cd1dyndns.com:5147/',
      'http://areacliente.cdsistemas.com.br': 'https://cloud134.cd1dyndns.com:5147/',
      'https://areacliente.cdsistemas.com.br': 'https://cloud134.cd1dyndns.com:5147/',
      'https://dh7xfloraq6x1.cloudfront.net': 'https://cloud134.cd1dyndns.com:5147/',
      'https://d2gkignakqdiax.cloudfront.net': 'https://cloud103.cd1dyndns.com:5147/',
      'http://localhost:4202': 'https://localhost:7146/',
      'https://cloud103.cd1dyndns.com:5148': 'https://cloud103.cd1dyndns.com:5147/',
      'https://d144flfkwf1ci5.cloudfront.net': 'https://guardeperto.cd1dyndns.com:7000/',
    };
    const url = window.location.origin;
    if (urls.hasOwnProperty(url)){
        return urls[url];
    }
    const msg = 'Nenhuma configuração encontrada para a URL';
    alert(msg);
    throw new Error(msg);
};

export const getDatabase = (): string => {
  const urls: {[key: string]: string} = {
    // Ambiente de produção
    'http://areadocliente.smartboxbrasil.com.br.s3-website-sa-east-1.amazonaws.com': 'Smartbox',
    'http://areadocliente.youboxselfstorage.com.br.s3-website-sa-east-1.amazonaws.com': 'Youbox',
    'http://areadocliente.weboxstorage.com.s3-website-sa-east-1.amazonaws.com': 'WeBox',
    'https://areadocliente.weboxstorage.com': 'WeBox',
    'https://areadocliente.smartboxbrasil.com.br': 'Smartbox',
    'https://d3h1e8vtyr87vq.cloudfront.net': 'Smartbox',
    'http://areadocliente.guardeperto.com.br.s3-website-sa-east-1.amazonaws.com': 'GuardePertoTeste',
    'http://areadocliente.smartboxbrasil.com.br': 'Smartbox',
    'http://areadocliente.anexxo.com.br.s3-website-sa-east-1.amazonaws.com': 'Anexxo',
    'http://areacliente.cdsistemas.com.br': 'DemoSelfStorage',
    'https://areacliente.cdsistemas.com.br': 'DemoSelfStorage',
    'https://dh7xfloraq6x1.cloudfront.net': 'DemoSelfStorage',
    'https://d2gkignakqdiax.cloudfront.net': 'WeBox',
    'https://d144flfkwf1ci5.cloudfront.net': 'GuardePertoTeste',

    // Ambiente de desenvolvimento
    'http://localhost:4202': 'GuardePertoCasa',
  };
  const url = window.location.origin;
  if (urls.hasOwnProperty(url)){
    return urls[url];
  }
  const msg = 'Nenhum banco de dados encontrado';
  alert(msg);
  throw new Error(msg);
};
