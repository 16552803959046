import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ChangePasswordService} from "../change-password/change-password.service";
import {lastValueFrom} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-troca-obrigatoria-senha',
  templateUrl: './troca-obrigatoria-senha.component.html',
  styleUrls: ['./troca-obrigatoria-senha.component.css']
})
export class TrocaObrigatoriaSenhaComponent {
  public form: FormGroup = new FormGroup({
    newPassword: new FormControl('', [Validators.required]),
    confirmNewPassword: new FormControl('', [Validators.required]),
  });

  public constructor(
    private changePasswordService: ChangePasswordService,
    private matDialogRef: MatDialogRef<ChangePasswordService>,
  ) {
  }

  protected async save(): Promise<void> {
    try {
      this.changePasswordService.showSpinner();
      await lastValueFrom(this.changePasswordService.setNewPassword(
        this.form.get('newPassword')?.value,
        this.form.get('confirmNewPassword')?.value
      ));
      this.changePasswordService.toasterSuccess('Senha alterada com sucesso!');
      this.matDialogRef.close();
    } catch (e: any) {
      this.changePasswordService.toasterError(e['error']);
    } finally {
      this.changePasswordService.hideSpinner();
    }
  }
}
