import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';
import {PagamentoFaturaComponent} from "./payment-invoice.component";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";



@NgModule({
  declarations: [
    PagamentoFaturaComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
  ]
})
export class PagamentoFaturaModule { }
