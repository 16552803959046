<mat-card *ngIf="clientName">
  <div class="row">
    <div class="col-12">
      <h1 class="hello">Olá, {{clientName}}!</h1>
    </div>
  </div>
  <div class="row" *ngIf="dashboardStatus.INADIMPLENTE">
    <div class="col-12">
      <div class="alert alert-warning text-center">
        <strong>Constam em nossos registros que você está com alguma mensalidade em atraso. Por favor, faça o pagamento para garantir a continuidade do seu contrato e o livre acesso ao seu box.<br/></strong>
      </div>
    </div>
  </div>
  <div class="row" routerLink="/principal/cartoes-credito" *ngIf="dashboardStatus.MOSTRAR_BANNER_CARTAO_CREDITO; else cartaoCreditoCadastrado" >
    <div class="col-12">
      <div class="alert alert-info">
        <div class="row">
          <div class="col-xs-12 col-md-12 text-center">
            <strong>Cadastre seu cartão de crédito para cobrança automática agora mesmo!</strong>
            <br>
            Evite esquecimentos, juros e multas por atraso. Clique aqui e faça o cadastro!
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #cartaoCreditoCadastrado>
    <div class="row" routerLink="/principal/cartoes-credito">
      <div class="col-12">
        <div class="alert alert-success text-center">
          <strong>Você já possui um cartão de crédito cadastrado.</strong>
          <br>
          Não se preocupe pois seus contratos serão pagos automaticamente.
        </div>
      </div>
    </div>
  </ng-template>
  <mat-tab-group>
    <mat-tab label="Faturas em aberto">
      <br>
      <app-table [dataSource]="datasourceMovements"
                 [displayedColumns]="fieldsFatura"
                 [openRoute]="true"
                 defaultRoute="/principal/faturas/"
                 primaryKey="CODIGO_FATURA"
                 defaultEmptyResultMessage="Não há nenhuma cobrança em aberto no momento"
      ></app-table>
    </mat-tab>
    <mat-tab label="Contratos">
      <br>
      <app-table [dataSource]="dataSourceContracts"
                 [openRoute]="true"
                 [displayedColumns]="fieldsContrato"
                 defaultRoute="/principal/contrato/"
                 primaryKey="GEN_CONTRATO"
                 defaultEmptyResultMessage="Nenhum contrato ativo no momento"
      ></app-table>
<!--      <table mat-table [dataSource]="dataSourceContracts" class="mat-elevation-z8" *ngIf="dataSourceContracts.data.length; else semContrato">-->
<!--        <ng-container matColumnDef="GEN_CONTRATO">-->
<!--          <th mat-header-cell *matHeaderCellDef>Contrato</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.GEN_CONTRATO}} </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="DESCRICAO">-->
<!--          <th mat-header-cell *matHeaderCellDef>Box</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.DESCRICAO}} </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="DESCRICAO_LOJA">-->
<!--          <th mat-header-cell *matHeaderCellDef>Unidade</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.DESCRICAO_LOJA}} </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="DATA_LOCACAO">-->
<!--          <th mat-header-cell *matHeaderCellDef>Início do contrato</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.DATA_LOCACAO | date}} </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="VALOR_BASE">-->
<!--          <th mat-header-cell *matHeaderCellDef>Valor base</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.VALOR_BASE | currency: 'BRL'}} </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="FINALIZAR_CONTRATO_DATA">-->
<!--          <th mat-header-cell *matHeaderCellDef>Encerramento do contrato</th>-->
<!--          <td mat-cell *matCellDef="let element">{{element.FINALIZAR_CONTRATO_DATA ? (element.FINALIZAR_CONTRATO_DATA | date) : '(Em vigor)'}} </td>-->
<!--        </ng-container>-->
<!--        <ng-container matColumnDef="INADIMPLENCIA">-->
<!--          <th mat-header-cell *matHeaderCellDef>Status financeiro</th>-->
<!--          <td mat-cell *matCellDef="let element"> {{element.INADIMPLENCIA ? 'Inadimplente' : 'Em dia'}} </td>-->
<!--        </ng-container>-->
<!--        <tr mat-header-row *matHeaderRowDef="displayedColumnsContracts"></tr>-->
<!--        <tr [class]="row.INADIMPLENCIA ? 'text-danger': ''" mat-row *matRowDef="let row; columns: displayedColumnsContracts;" (click)="openContract(row.GEN_CONTRATO)"></tr>-->
<!--&lt;!&ndash;        <tr class="mat-row" *matNoDataRow>&ndash;&gt;-->
<!--&lt;!&ndash;          <td class="mat-cell" colspan="3">Nada encontrado para o filtro {{input.value}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--      </table>-->
    </mat-tab>
  </mat-tab-group>
</mat-card>
