<mat-card>
    <mat-card-header>
        <mat-icon style="cursor: pointer" routerLink="/principal/dashboard">arrow_back</mat-icon>
        <h2>Faturas</h2>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                  <app-table [dataSource]="dataSourceInvoices"
                             [displayedColumns]="fieldsFatura"
                             primaryKey="CODIGO_FATURA"
                             defaultEmptyResultMessage="Nenhuma cobrança encontrada"
                             defaultRoute="/principal/faturas/"
                  ></app-table>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
