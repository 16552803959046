<mat-card>
  <mat-card-header>
    <h1>Segunda via de boleto</h1>
  </mat-card-header>
  <mat-card-content class="overflow-x">
    <div class="row">
      <div class="col-xs-12 table-responsive">
        <table mat-table [dataSource]="dataSourceDuplicateInvoices">
          <ng-container matColumnDef="CODIGO_BOLETO">
            <th mat-header-cell *matHeaderCellDef>Boleto</th>
            <td mat-cell *matCellDef="let element">{{element.CODIGO_BOLETO}} </td>
          </ng-container>
          <ng-container matColumnDef="DATA_DOCUMENTO">
            <th mat-header-cell *matHeaderCellDef>Data do documento</th>
            <td mat-cell *matCellDef="let element">{{element.DATA_DOCUMENTO | date:"d/MM/y - EEE"}} </td>
          </ng-container>
          <ng-container matColumnDef="INCLUSAO_DATA">
            <th mat-header-cell *matHeaderCellDef>Data de emissão</th>
            <td mat-cell *matCellDef="let element">{{element.INCLUSAO_DATA | date:"d/MM/y - EEE"}} </td>
          </ng-container>
          <ng-container matColumnDef="VALOR_DOCUMENTO">
            <th class="align-center" mat-header-cell *matHeaderCellDef>Valor</th>
            <td class="align-right" mat-cell *matCellDef="let element"> {{element.VALOR_DOCUMENTO | currency: 'BRL'}} </td>
          </ng-container>
          <ng-container matColumnDef="VENCIMENTO">
            <th mat-header-cell *matHeaderCellDef>Vencimento</th>
            <td mat-cell *matCellDef="let element"> {{element.VENCIMENTO | date:"d/MM/y - EEE"}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsInvoices"></tr>
          <tr (click)="openInvoice(row.CODIGO_BOLETO)"  mat-row *matRowDef="let row; columns: displayedColumnsInvoices;" title="Clique para abrir este boleto"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">Nada encontrado para o filtro</td>
          </tr>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
