import { Injectable } from '@angular/core';
import {lastValueFrom, Observable} from 'rxjs';
import {BaseServiceService} from '../base-service.service';
import {AvailableClient} from '../models/AvailableClient';
import {getDatabase} from "../../environments/environment-functions";
import {SystemInfo} from "../models/SystemInfo";
import {TrocaObrigatoriaSenhaComponent} from "../troca-obrigatoria-senha/troca-obrigatoria-senha.component";


@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseServiceService{

  /**
   * Realiza o login do sistema
   * @login login
   * @password password
   */

  login(login: string, pword: string): Observable<string> {
    const load = {
      username: login,
      password: pword
    };
    return this.x(load);
  }

  public getClientsList(): Observable<AvailableClient[]>{
    return this.http.get(this.getUrl('available_clients_list')) as Observable<AvailableClient[]>;
  }

  public getVersionInfo(): Observable<SystemInfo> {
    return this.http.get(this.getUrl('informacoes_sistema')) as Observable<SystemInfo>;
  }

  public verificarTrocaSenha(): Observable<boolean> {
    return this.http.get(this.getUrl("client_area/verificar_troca_senha"), {
      headers: this.getHeaders(),
    }) as Observable<boolean>;
  }

  private x(load: object): Observable<any>{
    return this.http.post(this.getUrl('client_area/login'), load, {headers: {
      'x-database-group': getDatabase(),
    }});
  }

  public async mostrarModalTrocaSenha(): Promise<void> {
    try {
      this.showSpinner();
      const teste = await lastValueFrom(this.verificarTrocaSenha());
      this.hideSpinner();
      if(teste){
        await lastValueFrom(this.matDialogData.open(TrocaObrigatoriaSenhaComponent, {
          disableClose: true
        }).afterClosed());
      }
    } catch (e){
      console.log(e);
    } finally {
      this.hideSpinner();
    }
  }
}
