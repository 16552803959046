import { Injectable } from '@angular/core';
import {BaseServiceService} from "../base-service.service";
import {lastValueFrom, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoExpressaService extends BaseServiceService {
  private checkAutenticacaoExpressaFatura(faturaTokenVisualizacaoExpressa: string): Observable<string>{
    return this.get("client_area/authenticate_token_invoice/"  + faturaTokenVisualizacaoExpressa);
  }
  async autenticarToken(token: string): Promise<any> {
    if(!token){ // Não executar se estiver vazio
      return;
    }
    try{
      this.showSpinner();
      const resToken = await lastValueFrom(this.checkAutenticacaoExpressaFatura(token));
      localStorage.setItem('x-session-token-ac', resToken);
    } catch (error) {
      console.error(error);
    } finally {
      this.hideSpinner();
    }
  }
}

