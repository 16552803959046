<mat-card class="cardChangePassword">
  <mat-card-header>
    <mat-icon (click)="returnToDashboard()" style="cursor: pointer">arrow_back</mat-icon>
    <h2>Troca de senha</h2>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="changePasswordForm">
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Digite a nova senha</mat-label>
          <input matInput type="password" required formControlName="newPassword">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Digite novamente a nova senha</mat-label>
          <input matInput type="password" required formControlName="confirmNewPassword">
          <mat-error *ngIf="!validatePasswords()" >{{ getErrorMessage() }}</mat-error>
        </mat-form-field>
      </p>
      <p align="end">
        <button mat-stroked-button (click)="(changePassword())"  [disabled]="(!validatePasswords())">Salvar</button>
      </p>
    </form>
  </mat-card-content>
</mat-card>
