import { Component, OnInit } from '@angular/core';
import {MovementService} from './movement.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-movement',
  templateUrl: './movement.component.html',
  styleUrls: ['./movement.component.css']
})
export class MovementComponent implements OnInit {

  constructor(
      public service: MovementService,
      private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<any> {
    try {
      this.service.showSpinner();
      const id = this.route.snapshot.params["id"];
      const x = await this.service.getMovement(id);
      console.log(x);
      this.service.hideSpinner();
    } catch (e) {
      console.error(e);
    } finally {
      this.service.hideSpinner();
    }
  }
}
