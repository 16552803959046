import { Router } from '@angular/router';
import { MyProfileService } from './my-profile.service';
import { Component, OnInit } from '@angular/core';
import {MyProfile} from '../models/MyProfile';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  public myProfileArr: MyProfile = {} as MyProfile;

  constructor(
    private service: MyProfileService,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.service.showSpinner();
    this.service.getMyProfile().subscribe(data => {
      console.log(data);
      this.myProfileArr = data;
      this.service.hideSpinner();
    });
  }

  returnToDashboard(): void {
    this.route.navigate(['principal/dashboard']);
  }

}
