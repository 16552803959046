import { Observable } from 'rxjs';
import { Financial } from '../models/Financial';
import { BaseServiceService } from '../base-service.service';
import { Injectable } from '@angular/core';
import {Contract} from '../models/Contract';
import {InvoiceNew} from '../models/InvoiceNew';

@Injectable({
  providedIn: 'root'
})
export class FinancialService extends BaseServiceService {
  public getFinancialList(userCode: string, contractCode: string): Observable<Array<InvoiceNew>> {
    return this.get('client_area/financial/' + contractCode);
  }

  public getMyDebts(): Observable<Array<Contract>>{
    return this.get('client_area/my_debts');
  }

  public createInvoiceByMoment(movements: Array<number>): Promise<InvoiceNew>{
    return this.post('client_area/create_invoice_movement', movements).toPromise();
  }
}
