import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from './login.service';
import {Router} from '@angular/router';
import {getBaseUrl, getDatabase} from '../../environments/environment-functions';
import {AvailableClient} from '../models/AvailableClient';
import {lastValueFrom} from "rxjs";
import {SystemInfo} from "../models/SystemInfo";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../backgrounds.css']
})
export class LoginComponent implements OnInit {
  constructor(
      private loginService: LoginService,
      private router: Router,
  ) {

  }

  form: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  clientList: AvailableClient[] = [];
  systemInfo: SystemInfo = {} as SystemInfo;
  async ngOnInit(): Promise<any> {
    try {
      await this.bindDatabase();
      this.loginService.showSpinner();
      this.clientList = await lastValueFrom(this.loginService.getClientsList());
      this.systemInfo = await lastValueFrom(this.loginService.getVersionInfo());
    } catch (e) {
      console.error(e);
      this.loginService.toasterError('Houve um erro ao montar tela');
    } finally {
      this.loginService.hideSpinner();
    }
  }

  async bindDatabase(): Promise<any> {
    const field = getDatabase();
    if(field){
      localStorage.setItem('x-database-group', field);
      this.setCookie("x-database-group", field, 2);
    }
  }

  async login(): Promise<any> {
    try {
      this.loginService.showSpinner();
      const value = this.form.value;
      const data = await lastValueFrom(this.loginService.login(value?.login, value?.password));
      localStorage.clear();
      localStorage.setItem('x-session-token-ac', data);
      localStorage.setItem('x-database-group', value?.database);
      this.setCookie("x-session-token-ac", data, 2);
      this.setCookie("x-database-group", value?.database, 2);
      this.loginService.hideSpinner();
      await this.router.navigate(['/principal/dashboard']);
    } catch (error: any) {
      this.loginService.hideSpinner();
      console.error(error);
      this.loginService.toasterWarning(error.error, 'Erro');
    }

  }

  logo(): string {
    return getBaseUrl() + 'client_area/logo?database=' + getDatabase();
  }

  setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
}
