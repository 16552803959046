import { BoxAccessUserComponent } from './box-access-user/box-access-user.component';
import { FinancialComponent } from './financial/financial.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { OpenedEntriesComponent } from './opened-entries/opened-entries.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {TemplateComponent} from './template/template.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {ContractComponent} from './contract/contract.component';
import { DuplicateInvoiceComponent } from './duplicate-invoice/duplicate-invoice.component';
import {FinancialNewComponent} from './financial-new/financial-new.component';
import {MovementComponent} from './movement/movement.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import { CreditCardContractFormComponent } from './credit-card-contract-form/credit-card-contract-form.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { PagamentoFaturaComponent } from "./payment-invoice/payment-invoice.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'principal',
    component: TemplateComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'boleto/:id',
        component: InvoiceComponent,
      },
      {
        path: 'contrato/:id',
        component: ContractComponent,
      },
      {
        path: 'segunda-via-boleto',
        component: DuplicateInvoiceComponent,
      },
      {
        path: 'lancamentos-financeiros-abertos',
        component: OpenedEntriesComponent,
      },
      {
        path: 'meus-dados',
        component: MyProfileComponent,
      },
      {
        path: 'trocar-senha',
        component: ChangePasswordComponent
      },
      {
        path: 'contratos',
        component: ContractListComponent
      },
      {
        path: 'financeiro',
        component: FinancialComponent
      },
      {
        path: 'acesso-box-usuario/:id/:codigoContrato',
        component: BoxAccessUserComponent
      },
      // {
      //   path: 'pagamento-cartao/:id',
      //   component: PaymentCardComponent,
      // },
      {
        path: 'movimentacao/:id',
        component: MovementComponent,
      },
      {
        path: 'meus-debitos',
        component: FinancialNewComponent,
      },
      {
        path: 'faturas',
        component: InvoiceListComponent,
      },
      {
        path: 'faturas/:id',
        component: PagamentoFaturaComponent,
      },
      {
        path: 'cartoes-credito',
        component: CreditCardContractFormComponent,
      },
      {
        path: 'cadastro-cartao-credito',
        component: CreditCardFormComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
      RouterModule.forRoot(routes, { useHash: true }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
