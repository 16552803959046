import {ContractList} from '../models/ContractList';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {ContractListService} from './contract-list.service';
import {lastValueFrom} from "rxjs";
import {Field} from "@cdsistemas/ui";
import {CurrencyPipe, DatePipe} from "@angular/common";

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css']
})
export class ContractListComponent implements OnInit {
  public fieldsContrato: Field[] = [
    {
      fieldName: "GEN_CONTRATO",
      primaryKey: true,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName: "Nº",
      columnClasses: "text-center",
    },
    {
      fieldName: "DESCRICAO_LOJA",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName:"Unidade",
      columnClasses: "",
    },
    {
      fieldName: "DESCRICAO_BOX",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName:"Box",
      columnClasses: "",
    },
    {
      fieldName: "DATA_LOCACAO",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => this.datePipe.transform(value),
      canonicalName:"Data de locação",
      columnClasses: "text-center",
    },
    {
      fieldName: "FINALIZAR_CONTRATO_DATA",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value ? this.datePipe.transform(value) : '(Em vigor)',
      canonicalName:"Finalizado em",
      columnClasses: "text-center",
    },
    {
      fieldName: "VALOR_MENSALIDADE",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => this.currencyPipe.transform(value),
      canonicalName:"Mensalidade",
      columnClasses: "text-right",
    },
  ];
  public dataSourceContractList: MatTableDataSource<ContractList> = new MatTableDataSource<ContractList>();

  constructor(
    private service: ContractListService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
  ) {

  }

  async ngOnInit(): Promise<any> {
    try {
      this.service.showSpinner();
      this.dataSourceContractList.data = await lastValueFrom(this.service.getContractList());
    } catch (e){
      console.error(e);
    } finally {
      this.service.hideSpinner();
    }
  }
  async returnToDashboard(): Promise<any> {
    await this.router.navigate(['principal/dashboard']);
  }

}
