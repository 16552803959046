import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DuplicateInvoice } from '../models/DuplicateInvoice';
import { DuplicateInvoiceService } from './duplicate-invoice.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duplicate-invoice',
  templateUrl: './duplicate-invoice.component.html',
  styleUrls: ['./duplicate-invoice.component.css']
})
export class DuplicateInvoiceComponent implements OnInit {

  public duplicateInvoicesArr: DuplicateInvoice = {} as DuplicateInvoice;
  public codeUser: string | null = localStorage.getItem('CODIGO_CADASTRO_GERAL');
  public dataSourceDuplicateInvoices: MatTableDataSource<DuplicateInvoice> = new MatTableDataSource<DuplicateInvoice>();
  public displayedColumnsInvoices: string[];

  constructor(
    private service: DuplicateInvoiceService,
    public route: Router,
  ) {
    this.displayedColumnsInvoices = [
      'CODIGO_BOLETO',
      'DATA_DOCUMENTO',
      'INCLUSAO_DATA',
      'VENCIMENTO',
      'VALOR_DOCUMENTO',
    ];
  }

  ngOnInit(): void {
    this.service.showSpinner();
    this.service.getOpenedInvoices(this.codeUser ?? "").subscribe((duplicateInvoices) => {
      this.duplicateInvoicesArr = duplicateInvoices;
      this.dataSourceDuplicateInvoices = new MatTableDataSource<DuplicateInvoice>(duplicateInvoices as any);
      this.service.hideSpinner();
    });
  }

  openInvoice(id: number): void {
    this.route.navigate(['principal/boleto/' + id]);
  }
}
