import { Component, OnInit } from '@angular/core';
import {InvoiceService} from './invoice.service';
import {Invoice} from '../models/Invoice';
import {ActivatedRoute, Router} from '@angular/router';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  public invoice: Invoice = {} as Invoice;
  public id: number;
  public barCodeValue: string = "";
  public isOverdue: boolean;
  public additionalTax: number;

  constructor(
    private service: InvoiceService,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private router: Router,
  ) {
    this.id = this.route.snapshot.params["id"];
    this.isOverdue = false;
    this.additionalTax = 0;
    this.get();
  }

  ngOnInit(): void {
  }

  get(): void {
    this.service.showSpinner();
    this.service.getInvoice(this.id).subscribe((data) => {
      this.service.hideSpinner();
      this.invoice = data;
      this.barCodeValue = data.CODIGO_BARRAS;
      this.isOverdue = data.VENCIDO;
      if (this.isOverdue) {
        this.additionalTax = data.VALOR_ADICIONAL;
      }
    }, (error) => {
      this.service.hideSpinner();
      console.error(error);
      this.service.toasterWarning(error.error ?? 'Houve um erro');
    });
  }

  openInvoice(): void{
    this.service.showSpinner();
    this.service.getBankInvoice(this.id).subscribe((data) => {
      this.service.hideSpinner();
      this.service.toasterInfo('Caso seu boleto não esteja visível, verifique se o seu navegador bloqueou algum popup para esta página.', 'Dica');
      const file = new Blob([data],{ type: 'text/html' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }, (error) => {
      this.service.hideSpinner();
      console.error(error);
      this.service.toasterError('Erro ao gerar boleto');
    });
  }

  payCard(invoiceId: string): void {
    this.router.navigate(['principal/pagamento-cartao/' + invoiceId]);
  }

  payPix(invoiceId: string): void {
    this.router.navigate(['principal/pagamento-pix/' +  invoiceId]);
  }

  copyBarCode(): void{
    this.clipboard.copy(this.barCodeValue);
    this.service.toasterSuccess('Código de barras copiado!');
  }
  returnToDashboard(): void{
    this.router.navigate(['principal/dashboard']);
  }
}
