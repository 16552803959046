import {Component, OnInit} from '@angular/core';
import {ContractService} from './contract.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ContractDetail} from '../models/ContractDetail';
import {Charge} from '../models/Charge';
import {MatTableDataSource} from '@angular/material/table';
import {BoxAccessUsers} from '../models/BoxAccessUsers';
import {Parameters} from '../models/Parameters';
import {Financial} from '../models/Financial';
import {FinancialService} from '../financial/financial.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {defaultNgxCurrency, Field, FieldBadge} from "@cdsistemas/ui";
import {InvoiceNew} from "../models/InvoiceNew";
import {lastValueFrom} from "rxjs";
import {CurrencyPipe, DatePipe} from "@angular/common";

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

  public contract: ContractDetail = {} as ContractDetail;
  public parameters: Parameters = {} as Parameters;
  public id: string;
  public dataSourceFinancial: MatTableDataSource<InvoiceNew> = new MatTableDataSource<InvoiceNew>();
  public form: FormGroup = new FormGroup<any>({
    DESCRICAO_LOJA: new FormControl(''),
    DATA_LOCACAO: new FormControl(''),
    DATA_PREVISAO_TERMINO: new FormControl(''),
    FINALIZAR_CONTRATO_DATA: new FormControl(''),
    VALOR_BASE: new FormControl(''),
    VALOR_MENSALIDADE: new FormControl(''),
  });

  public fieldsFatura: Field[] = [
    {
      fieldName: "CODIGO_FATURA",
      primaryKey: true,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName: "Nº",
      columnClasses: "text-center",
    },
    {
      fieldName: "MES_ANO",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName: "Mês/Ano",
      columnClasses: "text-center",
    },
    {
      fieldName: "DESCRICAO_FATURA",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName: "Descrição",
      columnClasses: "",
    },
    {
      fieldName: "DATA_VENCIMENTO",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => this.datePipe.transform(value),
      canonicalName: "Vencimento",
      columnClasses: "text-center",
    },
    {
      fieldName: "VALOR_TOTAL",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: false,
      visible: true,
      fieldOrder: 1,
      pipe: value => this.currencyPipe.transform(value),
      canonicalName: "Valor",
      columnClasses: "text-right",
    },
    {
      fieldName: "DESCRICAO_STATUS",
      primaryKey: false,
      isCheckbox: false,
      openRoute: true,
      isBadge: true,
      badgeConfig: (data: InvoiceNew) => {
        let aux = {
          text: data.DESCRICAO_STATUS,
          color:
            data.CODIGO_STATUS == 1 ? "bg-info" : // Pendente
            data.CODIGO_STATUS == 2 ? "bg-danger" : // Vencido
            data.CODIGO_STATUS == 4 ? "bg-success" : // Pago
              "bg-dark",
        } as FieldBadge;
        return [aux];
      },
      visible: true,
      fieldOrder: 1,
      pipe: value => value,
      canonicalName: "Status",
      columnClasses: "text-center",
    },
  ];

  public dataSourceBoxAccessUsers: MatTableDataSource<BoxAccessUsers> = new MatTableDataSource<BoxAccessUsers>();
  public displayedColumnsBoxAccessUsers: Array<string>;

  public formUsuarioPrincipal: FormGroup = new FormGroup({
    CA_NOME_DISPLAY: new FormControl('', [Validators.required]),
    CA_CODIGO_ACESSO: new FormControl('', [Validators.required]),
  });
  constructor(
    private service: ContractService,
    private route: ActivatedRoute,
    private router: Router,
    private serviceFinancial: FinancialService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
  ) {
    this.displayedColumnsBoxAccessUsers = [
      'CPF',
      'NOME',
      'MENSAGEM_ACESSO_LIBERADO',
      'NOME_DISPLAY',
      'CODIGO_ACESSO'
    ];

    this.id = this.route.snapshot.params["id"];
  }

  async ngOnInit(): Promise<any> {
    try {
      this.service.showSpinner();
      this.contract = await lastValueFrom(this.service.getContract(this.id));
      const faturas = await lastValueFrom(this.serviceFinancial.getFinancialList('', this.id));
      this.dataSourceFinancial = new MatTableDataSource<InvoiceNew>(faturas);
      this.dataSourceBoxAccessUsers = new MatTableDataSource<BoxAccessUsers>(this.contract.USUARIOS_ACESSO);
      if (this.contract.CONTRATO.INADIMPLENCIA){
        this.service.toasterWarning('Parece que este contrato está inadimplente. Por favor, faça a regularização para garantir acesso ao box.', 'Importante');
      }
      this.formUsuarioPrincipal.patchValue(this.contract.CADASTRO_GERAL);
      this.form.patchValue(this.contract.CONTRATO);
    } catch (e){
      console.error(e);
    } finally {
      this.service.hideSpinner();
    }
  }

  returnToDashboard(): void{
    this.router.navigate(['principal/contratos']);
  }

  public async includeNewBoxUser(codigoContrato: string): Promise<any> {
    await this.router.navigate(['principal/acesso-box-usuario/0/' + codigoContrato]);
  }

  public saveMainUserData(): void{
    this.service.showSpinner();
    this.service.saveMainAccessControl(this.id, this.formUsuarioPrincipal.getRawValue()).subscribe(data => {
      this.service.hideSpinner();
      this.service.toasterSuccess('Dados atualizados', 'Sucesso!');
    }, err => {
      this.service.hideSpinner();
      this.service.toasterError('Houve um erro ao salvar seus dados. Tente mais tarde');
    });
  }

  public loadDataBoxAccessUser = (id: string, codigoContrato: number):void => {
    if (id.toString().trim() === '') {
      this.service.hideSpinner();
      return;
    }
    if (codigoContrato.toString().trim() === '') {
      this.service.hideSpinner();
      return;
    }
    // localStorage.setItem('codigo-contrato', codigoContrato.toString());
    // localStorage.setItem('id-box-access-user', id);
    this.router.navigate(['principal/acesso-box-usuario/' + id + '/' + codigoContrato]);
  }
  protected readonly defaultNgxCurrency = defaultNgxCurrency;
}
