<h1 mat-dialog-title>Sucesso!</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-success">
        <h2>Sucesso!</h2>
        <p>Não se preocupe. A partir de agora, no vencimento de cada contrato ativo, o pagamento será feito automaticamente.</p>
        <p>Muito obrigado!</p>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Fechar</button>
</div>

