<mat-card *ngIf="myProfileArr">
    <mat-card-header>
        <mat-icon (click)="returnToDashboard()" style="cursor: pointer">arrow_back</mat-icon>
        <h2>Meus dados</h2>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col-12">
          <div class="alert alert-info text-center">
            <strong>Qualquer alteraçāo cadastral deve ser solicitada via telefone ou e-mail ou presencialmente em uma de nossas unidades.</strong>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Nome/Razão social</mat-label>
            <input type="text" matInput [value]="myProfileArr.NOME" readonly>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Documento</mat-label>
            <input type="text" matInput [value]="(myProfileArr.CODIGO_TIPO_PESSOA == 'F' ?  myProfileArr.CPF : myProfileArr.CNPJ)" mask="CPF_CNPJ" readonly>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>Celular</mat-label>
            <input type="text" matInput [value]="myProfileArr.TEL_CELULAR" mask="(99) 99999-9999" readonly>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-4">
          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input type="text" matInput [value]="myProfileArr.EMAIL" readonly>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <mat-form-field>
            <mat-label>Endereço</mat-label>
            <input type="text" matInput [value]="myProfileArr.LOGRADOURO + ', ' + myProfileArr.NUMERO + (myProfileArr.COMPLEMENTO ? ', ' + myProfileArr.COMPLEMENTO : '')" readonly>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Bairro</mat-label>
            <input type="text" matInput [value]="myProfileArr.BAIRRO" readonly>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-md-3">
          <mat-form-field>
            <mat-label>Cidade</mat-label>
            <input type="text" matInput [value]="myProfileArr.CIDADE + ' - ' + myProfileArr.UF" readonly>
          </mat-form-field>
        </div>
      </div>
<!--        <mat-list class="myProfileList">-->
<!--            <mat-list-item>-->
<!--                {{ myProfileArr.NOME}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider></mat-divider>-->
<!--            <mat-list-item>-->
<!--                {{ (myProfileArr.CODIGO_TIPO_PESSOA == "F" ?  myProfileArr.CPF : myProfileArr.CNPJ) | mask: "CPF_CNPJ" }}-->
<!--            </mat-list-item>-->
<!--            <mat-divider></mat-divider>-->
<!--            <mat-list-item *ngIf="myProfileArr.TEL_CELULAR">-->
<!--                {{ myProfileArr.TEL_CELULAR | mask: "(00) 00000-0000 || (00) 0000-0000" }}-->
<!--            </mat-list-item>-->
<!--            <mat-divider *ngIf="myProfileArr.TEL_CELULAR"></mat-divider>-->
<!--            <mat-list-item *ngIf="myProfileArr.TEL_TRABALHO">-->
<!--                {{ myProfileArr.TEL_TRABALHO | mask: "(00) 00000-0000 || (00) 0000-0000"}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider *ngIf="myProfileArr.TEL_TRABALHO"></mat-divider>-->
<!--            <mat-list-item *ngIf="myProfileArr.TEL_OUTROS">-->
<!--                {{ myProfileArr.TEL_OUTROS | mask: "(00) 00000-0000 || (00) 0000-0000"}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider *ngIf="myProfileArr.TEL_OUTROS"></mat-divider>-->
<!--            <mat-list-item *ngIf="myProfileArr.TEL_RESIDENCIAL">-->
<!--                {{ myProfileArr.TEL_RESIDENCIAL | mask: "(00) 00000-0000 || (00) 0000-0000"}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider *ngIf="myProfileArr.TEL_RESIDENCIAL"></mat-divider>-->
<!--            <mat-list-item>-->
<!--                {{ myProfileArr.EMAIL}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider></mat-divider>-->
<!--            <mat-list-item>-->
<!--                {{ myProfileArr.LOGRADOURO + ', ' + myProfileArr.NUMERO + (myProfileArr.COMPLEMENTO ? ', ' + myProfileArr.COMPLEMENTO : '')}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider></mat-divider>-->
<!--            <mat-list-item>-->
<!--                {{ myProfileArr.BAIRRO}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider></mat-divider>-->
<!--            <mat-list-item>-->
<!--                {{ myProfileArr.CIDADE}} -  {{myProfileArr.UF}}-->
<!--            </mat-list-item>-->
<!--            <mat-divider></mat-divider>-->
<!--        </mat-list>-->
    </mat-card-content>
</mat-card>
