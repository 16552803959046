import { Component } from '@angular/core';

@Component({
  selector: 'app-sucesso-inclusao-cartao',
  templateUrl: './sucesso-inclusao-cartao.component.html',
  styleUrls: ['./sucesso-inclusao-cartao.component.css']
})
export class SucessoInclusaoCartaoComponent {

}
