import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {PagamentoFaturaService} from "../payment-invoice/payment-invoice.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-termos-uso-recorrencia',
  templateUrl: './termos-uso-recorrencia.component.html',
  styleUrls: ['./termos-uso-recorrencia.component.css']
})
export class TermosUsoRecorrenciaComponent implements OnInit {
  public constructor(
    private matDialog: MatDialog,
    private faturaService: PagamentoFaturaService,
  ) {

  }
  public termos: string = "";
  async ngOnInit(): Promise<void> {
    try {
      this.faturaService.showSpinner();
      this.termos = await lastValueFrom(this.faturaService.getTermosUsoRecorrencia());
    } catch (e) {
      console.error(e);
    } finally {
      this.faturaService.hideSpinner();
    }
  }
}
