<mat-card *ngIf="contract">
  <mat-card-header>
    <mat-icon (click)="returnToDashboard()" style="cursor: pointer">arrow_back</mat-icon>
    <h2>Detalhe do contrato {{contract?.CONTRATO?.GEN_CONTRATO}} (Box {{contract.BOX?.DESCRICAO_BOX}})</h2>
  </mat-card-header>
  <mat-card-content>
    <div class="row" *ngIf="contract.CONTRATO?.INADIMPLENCIA">
      <div class="col-xs-12 col-md-12">
        <div class="alert alert-warning text-center">
          <strong>Parece que seu contrato não está em dia. Por favor, regularize o pagamento do seu contrato para garantir o acesso ao seu box.</strong>
        </div>
      </div>
    </div>
    <mat-tab-group>
<!--      <mat-tab label="Geral">-->
<!--        <div class="row">-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Unidade</label>-->
<!--            <p>{{contract.BOX?.DESCRICAO_LOJA}}</p>-->
<!--          </div>-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Data de locação</label>-->
<!--            <p>{{contract.CONTRATO?.DATA_LOCACAO | date}}</p>-->
<!--          </div>-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Previsão de encerramento</label>-->
<!--            <p>{{(contract.CONTRATO?.DATA_PREVISAO_TERMINO | date) }}</p>-->
<!--          </div>-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Encerramento</label>-->
<!--            <p>{{(contract.CONTRATO?.FINALIZAR_CONTRATO_DATA | date) || '(Contrato em vigor)' }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Valor base</label>-->
<!--            <p>{{contract.CONTRATO?.VALOR_BASE | currency: 'BRL'}}</p>-->
<!--          </div>-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Mensalidade</label>-->
<!--            <p>{{contract.CONTRATO?.VALOR_MENSALIDADE | currency: 'BRL'}}</p>-->
<!--          </div>-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Vencimento</label>-->
<!--            <p>Todo dia {{contract.CONTRATO?.DIA_VENCIMENTO }}</p>-->
<!--          </div>-->
<!--          <div class="col-xs-6 col-md-3">-->
<!--            <label>Financeiro</label>-->
<!--            <p>{{contract.CONTRATO?.INADIMPLENCIA ? "Inadimplente" : "Em dia"}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-tab>-->
      <mat-tab label="Geral">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <app-input label="Unidade" formControlName="DESCRICAO_LOJA" [formGroup]="form" [readonly]="true"></app-input>
            </div>
            <div class="col-xs-12 col-md-4">
              <app-datepicker label="Data de locacao" formControlName="DATA_LOCACAO" [formGroup]="form" [readonly]="true" style="width: 100%"></app-datepicker>
            </div>
            <div class="col-xs-12 col-md-4">
              <mat-form-field>
                <mat-label>Mensalidade</mat-label>
                <input matInput currencyMask [options]="defaultNgxCurrency" formControlName="VALOR_MENSALIDADE" [readonly]="true">
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Financeiro">
        <app-table [dataSource]="dataSourceFinancial"
                   [displayedColumns]="fieldsFatura"
                   defaultEmptyResultMessage="Nenhuma cobrança encontrada"
                   defaultRoute="/principal/faturas/"
        ></app-table>
      </mat-tab>
      <mat-tab label="Controle de acesso ao box">
        <mat-tab-group>
          <mat-tab label="Acesso principal">
            <form [formGroup]="formUsuarioPrincipal">
              <br>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="alert alert-info text-center">
                    <strong>Pode levar algum tempo até que qualquer alteração se reflita no controle de acesso ao seu box.</strong>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <mat-form-field class="full">
                    <mat-label>Nome no display</mat-label>
                    <input matInput maxlength="20" placeholder="Digite o nome" type="text" required formControlName="CA_NOME_DISPLAY">
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-md-6">
                  <mat-form-field class="full">
                    <mat-label>Código de acesso</mat-label>
                    <input matInput maxlength="10" placeholder="Digite o codigo de acesso" type="number" required formControlName="CA_CODIGO_ACESSO">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-12 text-right">
                  <button mat-button mat-raised-button color="primary" (click)="saveMainUserData()">Gravar</button>
                </div>
              </div>
            </form>
          </mat-tab>
          <mat-tab label="Acessos secundários">
            <table mat-table [dataSource]="dataSourceBoxAccessUsers" *ngIf="dataSourceBoxAccessUsers?.data?.length ?? 0 > 0 else semAcessosSecundarios">
              <ng-container matColumnDef="CPF">
                <th mat-header-cell *matHeaderCellDef>CPF</th>
                <td mat-cell *matCellDef="let element"> {{element.CPF | mask: "000.000.000-00"}}</td>
              </ng-container>
              <ng-container matColumnDef="NOME">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element"> {{element.NOME}}</td>
              </ng-container>
              <ng-container matColumnDef="MENSAGEM_ACESSO_LIBERADO">
                <th mat-header-cell *matHeaderCellDef>Liberado</th>
                <td mat-cell *matCellDef="let element"> {{element.ACESSO_LIBERADO == "S" ? "Sim" :  "Não"}} </td>
              </ng-container>
              <ng-container matColumnDef="NOME_DISPLAY">
                <th mat-header-cell *matHeaderCellDef>Nome no display</th>
                <td mat-cell *matCellDef="let element"> {{element.NOME_DISPLAY}}</td>
              </ng-container>
              <ng-container matColumnDef="CODIGO_ACESSO">
                <th mat-header-cell *matHeaderCellDef>Código de acesso</th>
                <td mat-cell *matCellDef="let element"> {{element.CODIGO_ACESSO}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsBoxAccessUsers"></tr>
              <tr (click)="loadDataBoxAccessUser(row.GEN_CONTRATO_CONTROLE_ACESSO, contract.CONTRATO.GEN_CONTRATO)" class="tr-box-access-user" mat-row *matRowDef="let row; columns: displayedColumnsBoxAccessUsers;"></tr>
            </table>
            <ng-template #semAcessosSecundarios>
              <br>
              <div class="alert alert-info text-center" >
                <strong>Nenhum registro encontrado</strong>
              </div>
            </ng-template>
            <div id="div-btn-box-access-user">
              <button (click)="includeNewBoxUser(contract.CONTRATO.GEN_CONTRATO.toString())" color="primary" id="btn-incluir" mat-raised-button>Incluir</button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
