import { BaseServiceService } from './../base-service.service';
import { DuplicateInvoice } from './../models/DuplicateInvoice';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DuplicateInvoiceService extends BaseServiceService {

  getOpenedInvoices(userCode: string): Observable<DuplicateInvoice> {
    return this.get('client_area/invoice');
  }

  public getBankInvoice(id: number): Observable<ArrayBufferLike> {
    const h = this.getHeaders();
    // @ts-ignore
    return this.http.get(this.getUrl('client_area/render_invoice/' + id), {headers: h, responseType: 'blob'});
  }

}
