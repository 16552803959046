import { Injectable } from '@angular/core';
import { BaseServiceService } from '../base-service.service';
import { Observable } from 'rxjs';
import {CreditCardList} from '../models/CreditCardList';

@Injectable({
  providedIn: 'root'
})
export class CreditCardContractService extends BaseServiceService{
  public getCreditCards(): Observable<Array<CreditCardList>> {
    return this.get('client_area/credit_cards') as Observable<Array<CreditCardList>>;
  }

  public deleteCreditCard(id: string): Observable<any>{
    return this.delete("client_area/delete_credit_card/" + id);
  }
}
