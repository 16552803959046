import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ContractComponent } from './contract/contract.component';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './login/login.module';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TemplateModule } from './template/template.module';
import { DashboardModule} from './dashboard/dashboard.module';
import { ForgotPasswordModule} from './forgot-password/forgot-password.module';
import {
  CommonModule, CurrencyPipe, DATE_PIPE_DEFAULT_OPTIONS, DatePipe, HashLocationStrategy,
  JsonPipe,
  LocationStrategy,
  NgOptimizedImage,
  registerLocaleData
} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { InvoiceModule} from './invoice/invoice.module';
import { ContractModule} from './contract/contract.module';
import { MatSelectModule } from '@angular/material/select';
import { DuplicateInvoiceComponent } from './duplicate-invoice/duplicate-invoice.component';
import { OpenedEntriesComponent } from './opened-entries/opened-entries.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule} from '@angular/material/icon';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { FinancialComponent } from './financial/financial.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutModule } from './checkout/checkout.module';
import { PaymentPixComponent } from './payment-pix/payment-pix.component';
import { PagamentoFaturaModule } from './payment-invoice/payment-invoice.module';
import { BoxAccessUserComponent } from './box-access-user/box-access-user.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmDeleteUserComponent } from './box-access-user/confirm-delete-user/confirm-delete-user.component';
import { MatTooltipModule} from '@angular/material/tooltip';
import { FinancialNewModule} from './financial-new/financial-new.module';
import { MovementModule} from './movement/movement.module';
import { InvoiceListModule} from './invoice-list/invoice-list.module';
import { CreditCardContractFormModule } from './credit-card-contract-form/credit-card-contract-form.module';
import { CreditCardFormModule } from './credit-card-form/credit-card-form.module';
import { PaymentCheckoutModalComponent } from './payment-checkout-modal/payment-checkout-modal.component';
import {IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask} from "ngx-mask";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {DatepickerModule, InputModule, SelectModule, TableModule} from "@cdsistemas/ui";
import {NgxCurrencyDirective} from "ngx-currency";
import { ModalCreditCardComponent } from './modal-credit-card/modal-credit-card.component';
import {MatRadioModule} from "@angular/material/radio";
import { TrocaObrigatoriaSenhaComponent } from './troca-obrigatoria-senha/troca-obrigatoria-senha.component';
import { TermosUsoRecorrenciaComponent } from './termos-uso-recorrencia/termos-uso-recorrencia.component';

registerLocaleData(localePt);
const maskConfig: Partial<IConfig> = {
  validation: true,
  allowNegativeNumbers: true,
  decimalMarker: ",",
  clearIfNotMatch: false,
  dropSpecialCharacters: true,
  thousandSeparator: "."
};
@NgModule({
  bootstrap: [
    AppComponent,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    DuplicateInvoiceComponent,
    OpenedEntriesComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    ContractListComponent,
    FinancialComponent,
    SafeHtmlPipe,
    ContractComponent,
    BoxAccessUserComponent,
    ConfirmDeleteUserComponent,
    ContractComponent,
    CheckoutComponent,
    // PaymentCardComponent,
    PaymentPixComponent,
    // GenericDialogComponent,
    PaymentCheckoutModalComponent,
    ModalCreditCardComponent,
    TrocaObrigatoriaSenhaComponent,
    TermosUsoRecorrenciaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    TemplateModule,
    DashboardModule,
    InvoiceModule,
    ContractModule,
    ForgotPasswordModule,
    HttpClientModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      timeOut: 5000,
      enableHtml: true,
    }),
    // NgxSpinnerModule,
    ReactiveFormsModule,
    CheckoutModule,
    PagamentoFaturaModule,
    FinancialNewModule,
    MovementModule,
    InvoiceListModule,
    MatMenuModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    CreditCardContractFormModule,
    CreditCardFormModule,
    MatTableModule,
    NgxSpinnerModule.forRoot({
      type: 'ball-scale-multiple'
    }),
    NgxMaskPipe,
    NgxMaskDirective,
    NgxExtendedPdfViewerModule,
    NgOptimizedImage,
    TableModule,
    InputModule,
    DatepickerModule,
    NgxCurrencyDirective,
    SelectModule,
    MatRadioModule,
    // NgxMaskDirective,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    JsonPipe,
    DatePipe,
    CurrencyPipe,
    {
      provide: LocationStrategy,
      // useClass: PathLocationStrategy
      useClass: HashLocationStrategy
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: "dd/MM/yyyy",
      },
    },
    provideEnvironmentNgxMask(maskConfig),
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler
    // }
  ]
})
export class AppModule { }
