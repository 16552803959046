<mat-card>
  <mat-card-header class="title-card-header">
    <h1>Financeiro</h1>
  </mat-card-header>
  <mat-card-content>
    <table class="" mat-table [dataSource]="dataSourceFinancial" >
      <ng-container matColumnDef="CODIGO_BOLETO">
        <th mat-header-cell *matHeaderCellDef>Boleto</th>
        <td class="no-break-word" mat-cell *matCellDef="let element">{{element.CODIGO_BOLETO}} </td>
      </ng-container>
      <ng-container matColumnDef="CODIGO_COMPLETO_LANCAMENTO">
        <th mat-header-cell *matHeaderCellDef>Lançamento</th>
        <td class="no-break-word" mat-cell *matCellDef="let element">{{element.CODIGO_COMPLETO_LANCAMENTO}} </td>
      </ng-container>
      <ng-container matColumnDef="DATA_EMISSAO">
        <th mat-header-cell *matHeaderCellDef>Emissão</th>
        <td class="no-break-word" mat-cell *matCellDef="let element">{{element.DATA_EMISSAO | date:"dd/MM/y - EEE"}} </td>
      </ng-container>
      <ng-container matColumnDef="DESCRICAO_LANCAMENTO">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td  mat-cell *matCellDef="let element">{{element.DESCRICAO_LANCAMENTO}} </td>
      </ng-container>
      <ng-container matColumnDef="DATA_VENCIMENTO">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td class="no-break-word" mat-cell *matCellDef="let element">{{element.DATA_VENCIMENTO | date:"dd/MM/y - EEE"}} </td>
      </ng-container>
      <ng-container matColumnDef="VALOR_PREVISAO">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td class="no-break-word align-right" class="" mat-cell *matCellDef="let element">{{element.VALOR_PREVISAO | currency: 'BRL'}} </td>
      </ng-container>
      <ng-container matColumnDef="DATA_REAL">
        <th mat-header-cell *matHeaderCellDef>Data do pagamento</th>
        <td class="no-break-word" mat-cell *matCellDef="let element">{{(element.DATA_REAL | date:"dd/MM/y - EEE") }} </td>
      </ng-container>
      <ng-container matColumnDef="VALOR_REAL_FORMATADO"  >
        <th mat-header-cell *matHeaderCellDef class="align-right">Valor pago</th>
        <td class="no-break-word align-right" mat-cell *matCellDef="let element"><div class="no-break-word align-right" [innerHTML]="element.VALOR_REAL_FORMATADO | safeHtml"></div> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsFinancials"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsFinancials;"></tr>
    </table>
  </mat-card-content>
</mat-card>
