// import { NgxMaskModule } from 'ngx-mask';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {NgxMaskDirective} from "ngx-mask";

@NgModule({
  declarations: [
  ],
  imports: [
    // NgxMaskModule,
    CommonModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  exports: [
    MatInputModule,
    MatCardModule,
    MatButtonModule,
  ],
})
export class LoginModule { }
