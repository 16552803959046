import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {CompanyName} from '../models/CompanyName';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseServiceService{

  public checkSession(userId: string, sessionId: string): Observable<any>{
    const x = {user_id: userId, session_id: sessionId};
    return this.post('login/check_session', x);
  }

  public getCompanyName(): Observable<CompanyName>{

    return this.get('client_area/company_name');
  }
}
