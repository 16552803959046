import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {CreditCardRequest} from '../models/CreditCardRequest';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService extends BaseServiceService{

  public createCreditCard(load: CreditCardRequest): Observable<any>{
    return this.post('client_area/create_credit_card', load);
  }
}
