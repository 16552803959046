import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TemplateService} from './template.service';
import {CompanyName} from '../models/CompanyName';
import {lastValueFrom} from "rxjs";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css', '../backgrounds.css']
})
export class TemplateComponent implements OnInit {

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private service: TemplateService,
    // private templateService: TemplateService
  ) {

  }

  shouldRun: boolean = true;
  companyName: string = "";

  ngOnInit(): void {
    this.checkIsLogged();
    this.getCompanyName().then(name => {
      this.companyName = 'Área do cliente - ' + name.name;
    }).catch(err =>  {
      console.error(err);
      this.toaster.error('Erro ao buscar nome da empresa');
      this.companyName = 'Área do cliente';
      this.router.navigate(['/login']);
    });
  }

  public async redirect(uri: string): Promise<any> {
    await this.router.navigate([uri]);
  }

  private checkIsLogged(): void{
    if (localStorage.length === 0){
      this.toaster.warning('Faça login novamente', 'Desconectado');
      this.router.navigate(['/login']);
    }
  }

  public async getCompanyName(): Promise<CompanyName> {
    return await lastValueFrom(this.service.getCompanyName());
  }

  public fecharMenu(menuTrigger: MatMenuTrigger): void {
    if (menuTrigger && menuTrigger.menuOpen) {
      menuTrigger.closeMenu();
    }
  }
}
