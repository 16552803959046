<mat-toolbar class="toolbar-cor">
  <mat-toolbar-row>
    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="nome-empresa">{{companyName}}</span>
    <span class="example-spacer"></span>
    <button mat-button [matMenuTriggerFor]="menu" title="Opções">
      <mat-icon class="">
        settings
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="redirect('principal/meus-dados')" >Meus dados</button>
      <button mat-menu-item (click)="redirect('principal/trocar-senha')" >Trocar senha</button>
    </mat-menu>
    <button mat-button routerLink="/" title="Sair" >
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar-row>
  <ngx-spinner></ngx-spinner>
</mat-toolbar>
<mat-drawer-container id="menu" class="example-container gradient-body" [hasBackdrop]="true" [autosize]="true">
  <mat-drawer #drawer [mode]="'side'" [position]="'start'">
    <mat-nav-list>
      <a mat-list-item (click)="redirect('principal/dashboard')"><mat-icon>home</mat-icon> Início</a>
      <a mat-list-item (click)="redirect('principal/contratos')"><mat-icon>history_edu</mat-icon> Contratos</a>
      <a mat-list-item (click)="redirect('principal/faturas')" ><mat-icon>request_quote</mat-icon> Faturas</a>
      <a mat-list-item (click)="redirect('principal/cartoes-credito')" ><mat-icon>credit_card</mat-icon> Cartões de crédito</a>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <div class="container" style="margin-top: 2vh">
      <ng-component></ng-component>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
