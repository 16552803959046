<h1 mat-dialog-title>Troca obrigatória da senha de acesso</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info">
        <h2>Importante</h2>
        <p>Para a sua segurança, sua senha de acesso precisa ser trocada. Por favor, informe sua nova senha.</p>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <mat-form-field>
          <mat-label>Senha</mat-label>
          <input type="password" matInput formControlName="newPassword">
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-md-6">
        <mat-form-field>
          <mat-label>Confirme a senha</mat-label>
          <input type="password" matInput formControlName="confirmNewPassword">
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save()">Ok</button>
</div>
