<mat-card>
    <mat-card-header>
        <mat-card-title id="mat-card-title">
          <mat-icon (click)="backToContract()"  style="cursor: pointer">arrow_back</mat-icon>
          Usuário com acesso ao box
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row center">
        <div class="col-xs-12">
          <div class="alert alert-info text-center">
            <strong>Pode demorar algum tempo para esta alteraçāo se refletir no equipamento de controle de acesso ao seu box</strong>
          </div>
        </div>
      </div>
      <mat-tab-group>
        <mat-tab label="Dados do usuário">
          <br>
          <form [formGroup]="insertUpdateFormBoxAccessUpdate">
            <p class="input-ocultar">
              <mat-form-field appearance="fill">
                <input matInput type="text" value="" formControlName="id">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>CPF</mat-label>
                <input matInput type="text" (blur)="loadUser()"  value="" required formControlName="cpf" mask="000.000.000-00">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Nome</mat-label>
                <input matInput type="text" (blur)="setDisplayName()"  value="" maxlength="60" required formControlName="nome">
              </mat-form-field>
            </p>
            <p class="input-ocultar">
              <mat-form-field appearance="fill">
                <input matInput type="text" value="" required formControlName="codigoCadastroGeral">
              </mat-form-field>
            </p>

            <p>
              <mat-slide-toggle color="primary" formControlName="liberado">
                  Liberado
              </mat-slide-toggle>
            </p>

            <p>
              <mat-form-field appearance="fill">
                <mat-label>Observação</mat-label>
                <input matInput type="text" value="" maxlenght="80" formControlName="observacao">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Nome no display</mat-label>
                <input matInput type="text" value="" maxlength="20" required formControlName="nomeDisplay">
              </mat-form-field>
            </p>
            <p class="">
              <mat-form-field class="codigo-acesso" appearance="fill">
                <mat-label>Código de acesso</mat-label>
                <input matInput type="number" value="" pattern="/^-?\d+\.?\d*$/" maxlength="10"
                onKeyPress="if(this.value.length===10) return false;"
                required formControlName="codigoAcesso">
              </mat-form-field>
              <span class="spn-btn-codigo-acesso">
                <button (click)="getAccessCode()"  mat-stroked-button matTooltip="Gerar código de acesso aleatoriamente">Gerar</button>
              </span>
            </p>
            <div id="div-box-access-user" align="end" >
              <button (click)="setBoxAccessUser()"  mat-stroked-button>Salvar</button>
              <button (click)="openDialog()" *ngIf="this.id"  id="btn-delete-user"  mat-stroked-button color="warn">Excluir</button>
            </div>
          </form>
        </mat-tab>
        <mat-tab id="mat-tab-info" label="Informações">
          <br>
          <form [formGroup]="infoUpdateFormBoxAccessUpdate">
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Incluído em:</mat-label>
                <input matInput type="text" value="" formControlName="incluido">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Última atualização: </mat-label>
                <input matInput type="text" value="" formControlName="atualizacao">
              </mat-form-field>
            </p>
          </form>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
</mat-card>


