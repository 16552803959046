import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';

@Injectable({
  providedIn: 'root'
})
export class MovementService extends BaseServiceService{
  public getMovement(id: string): Promise<any>{
    return this.get('client_area/movement/' + id).toPromise();
  }
}
