import { Component, OnInit } from '@angular/core';
import {ForgotPasswordService} from './forgot-password.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private service: ForgotPasswordService,
    private router: Router,
  ) { }

  public sendResetEmailForm: FormGroup = new FormGroup<any>({});
  public nextStepVerificationCode: boolean = false;
  public nextStepNewPassword: boolean = false
  public validateCodeForm: FormGroup = new FormGroup<any>({});
  public setNewPasswordForm: FormGroup  = new FormGroup<any>({});

  ngOnInit(): void {
    this.sendResetEmailForm = new FormGroup({
      documento: new FormControl('', [Validators.required])
    });
    this.validateCodeForm = new FormGroup({
      code: new FormControl('', [Validators.required])
    });
    this.setNewPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required]),
      newPasswordConfirm: new FormControl('', [Validators.required]),
    });
    this.nextStepNewPassword = false;
    this.nextStepVerificationCode = false;
  }

  validateDocumentClick(): void{
    const documento = this.sendResetEmailForm.value.documento;
    this.validateEmail(documento);
  }

  validateEmailCode(): void {
    const documento = this.sendResetEmailForm.value.documento;
    const code = this.validateCodeForm.value.code;
    this.validateCode(documento, code);
  }

  changePassword(): void {
    const documento = this.sendResetEmailForm.value.documento;
    const code = this.validateCodeForm.value.code;
    const password = this.setNewPasswordForm.value.newPassword;
    const passwordConfirm = this.setNewPasswordForm.value.newPasswordConfirm;
    this.validatePasswordChange(documento, code, password, passwordConfirm);
  }

  /**
   * Faz o envio de e-mail para o solicitante
   */
  protected validateEmail(documento: string): void {
    this.service.showSpinner();
    this.service.sendEmailResetPassword(documento).subscribe((data) => {
      this.service.hideSpinner();
      this.service.toasterInfo('Verifique a sua caixa de e-mail', 'E-mail enviado!');
      this.nextStepVerificationCode = true;
    }, error => {
      this.service.hideSpinner();
      this.service.toasterWarning(error.error, 'Erro');
    });
  }

  /**
   *
   */
  protected validateCode(documento: string, code: string): void {
    this.service.showSpinner();
    this.service.validateCode(documento, code).subscribe((data) => {
      this.service.hideSpinner();
      this.service.toasterInfo('Código de verificação validado!');
      this.nextStepVerificationCode = true;
    }, (error) => {
      this.service.hideSpinner();
      this.service.toasterWarning(error.error ?? 'Houve um erro ao validar o código de verificação');
    });
  }

  protected validatePasswordChange(documento: string, code: string, password: string, confirmPassword: string): void {
    this.service.showSpinner();
    this.service.setNewPassword(documento, code, password, confirmPassword).subscribe((data) => {
      this.service.hideSpinner();
      this.service.toasterSuccess('Senha alterada com sucesso!');
      this.router.navigate(['login']);
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterWarning(err.error ?? 'Erro ao gravar nova senha');
    });
  }
}
