<h1 mat-dialog-title>Importante</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info">
        <h2>Confira os dados antes de confirmar</h2>
        <p>Será debitado neste momento o valor de <strong>{{valorPagar | currency}}</strong>.</p>
        <p>Caso tenha alguma dúvida, não hesite em nos contactar.</p>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" mat-raised-button color="primary">Confirmar</button>
  <button mat-button [mat-dialog-close]="false">Fechar</button>
</div>

