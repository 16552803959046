<div class="div-login gradient-body">
  <form class="example-form" [formGroup]="form">
    <mat-card class="outerMatCard">
      <img [src]="logo()" alt="Logomarca" class="login-logomarca img-fluid">
      <mat-card class="innerMatCard">
        <mat-card-title>Área do Cliente</mat-card-title>
        <br>
        <mat-card-content>
          <mat-form-field>
            <mat-label>CPF ou CNPJ</mat-label>
            <input class="input-adjusted" matInput type="text" formControlName="login" mask="000.000.000-00||00.000.000/0000-00">
          </mat-form-field>
          <br>
          <mat-form-field>
            <mat-label>Senha</mat-label>
            <input class="input-adjusted" type="password" matInput formControlName="password">
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button color="primary" [disabled]="!form.valid" (click)="login()" type="submit">Ok</button>
          <a mat-button [routerLink]="['/forgot-password']">Esqueci minha senha</a>
        </mat-card-actions>
      </mat-card>
      <div class="row">
        <div class="col-xs-12">
          <p style="right: auto"><small>Desenvolvido pela <a href="https://cdsistemas.com.br" target="_blank">C&D Sistemas</a>. (Versão {{systemInfo.lastBuildNumber}} - {{systemInfo.lastBuildDate | date}})</small></p>
        </div>
      </div>
    </mat-card>
  </form>
  <ngx-spinner></ngx-spinner>
</div>
