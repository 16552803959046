import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmacacao-inclusao-cartao',
  templateUrl: './confirmacacao-inclusao-cartao.component.html',
  styleUrls: ['./confirmacacao-inclusao-cartao.component.css']
})
export class ConfirmacacaoInclusaoCartaoComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public valorPagar: number,
  ) {
  }
}
