<mat-card *ngIf="invoice">
    <mat-card-header>
        <mat-icon (click)="returnToDashboard()" style="cursor: pointer">arrow_back</mat-icon>
        <h2>Detalhe da cobrança</h2>
    </mat-card-header>
    <mat-card-content>
        <div class="row" *ngIf="invoice.DATA_PAGAMENTO != null">
            <div class="col-xs-12 col-md-12">
                <div class="alert alert-success text-center">
                    <strong>Esta cobrança foi paga no dia {{invoice.DATA_PAGAMENTO | date}}.</strong>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-12">
                <mat-card>
                    <mat-card-header>
                       <h3>{{invoice.DESCRICAO_FATURA}}</h3>
                    </mat-card-header>
                    <mat-card-content>
                        <hr>
                        <div [title]="(item.DATA_PAGAMENTO ? 'Este item foi pago em ' + (item.DATA_PAGAMENTO | date) : '')" [class]="'row borda ' + (item.DATA_PAGAMENTO ? 'alert-success riscado' : '')" *ngFor="let item of invoice.LANCAMENTOS">
                            <div class="col-xs-8 col-md-8">
                                <span>{{item.DESCRICAO_LANCAMENTO}}</span>
                                <br>
                                <span>
                                  <small *ngIf="item.DATA_PAGAMENTO; else msgPagamento">Pago em {{item.DATA_PAGAMENTO | date}}</small>
                                  <ng-template #msgPagamento>
                                    <small>Vencimento em {{item.DATA_VENCIMENTO | date}}</small>
                                  </ng-template>
                                </span>
                            </div>
                            <div class="col-xs-4 col-md-4 text-right">
                                {{item.VALOR_PREVISAO | currency: 'BRL'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-8 col-md-8">
                                Vencimento da fatura:
                            </div>
                            <div class="col-xs-4 col-md-4 text-right">
                                <strong>{{invoice.DATA_VENCIMENTO | date}}</strong>
                            </div>
                        </div>
                        <hr *ngIf="invoice.ACRESCIMO > 0 && !invoice.DATA_PAGAMENTO">
                        <div class="row" *ngIf="invoice.ACRESCIMO > 0 && !invoice.DATA_PAGAMENTO">
                            <div class="col-xs-8 col-md-8">
                                Subtotal:
                            </div>
                            <div class="col-xs-4 col-md-4 text-right">
                                <strong>{{invoice.VALOR_TOTAL - invoice.ACRESCIMO + invoice.DESCONTO | currency: 'BRL'}}</strong>
                            </div>
                        </div>
                        <hr *ngIf="invoice.ACRESCIMO > 0 && !invoice.DATA_PAGAMENTO">
                        <div class="row click" *ngIf="invoice.ACRESCIMO > 0 && !invoice.DATA_PAGAMENTO" (click)="disclaimerAcrescimos()">
                            <div class="col-xs-8 col-md-8">
                                Acréscimo:
                            </div>
                            <div class="col-xs-4 col-md-4 text-right acrescimos">
                                {{invoice.ACRESCIMO | currency: 'BRL'}}
                            </div>
                        </div>
                        <hr *ngIf="invoice.DESCONTO > 0 && !invoice.DATA_PAGAMENTO">
                        <div class="row" *ngIf="invoice.DESCONTO > 0 && !invoice.DATA_PAGAMENTO">
                            <div class="col-xs-8 col-md-8">
                                Desconto:
                            </div>
                            <div class="col-xs-4 col-md-4 text-right">
                                {{invoice.DESCONTO | currency: 'BRL'}}
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-xs-8 col-md-8">
                                Total a pagar:
                            </div>
                            <div class="col-xs-4 col-md-4 valor-total text-right">
                                <strong>{{invoice.VALOR_TOTAL | currency: 'BRL'}}</strong>
                            </div>
                        </div>
                        <!--<hr *ngIf="invoice.CODIGO_BOLETO  && invoice.DATA_PAGAMENTO == null">-->
                        <!--<div class="row" *ngIf="invoice.CODIGO_BOLETO  && invoice.DATA_PAGAMENTO == null">-->
                            <!--<div class="col-xs-8 col-md-10">-->
                                <!--Boleto:-->
                            <!--</div>-->
                            <!--<div class="col-xs-4 col-md-2">-->
                                <!--<button (click)="showBoleto()" class="fill" mat-button mat-stroked-button color="primary">Imprimir</button>-->
                            <!--</div>-->
                        <!--</div>-->
                        <hr *ngIf="invoice.CODIGO_NFSE">
                        <div class="row" *ngIf="invoice.CODIGO_NFSE">
                            <div class="col-xs-8 col-md-10">
                                Nota fiscal de serviço eletrônica:
                            </div>
                            <div class="col-xs-4 col-md-2">
                                <button (click)="showNfse()" class="fill" mat-button mat-stroked-button color="primary">Imprimir</button>
                            </div>
                        </div>
                        <hr *ngIf="invoice.DATA_PAGAMENTO">
                        <div class="row" *ngIf="invoice.DATA_PAGAMENTO">
                            <div class="col-xs-8 col-md-8">
                                Pago em:
                            </div>
                            <div class="col-xs-4 col-md-4 data-pagamento text-right">
                                <strong>{{invoice.DATA_PAGAMENTO | date}}</strong>
                            </div>
                        </div>
                        <hr *ngIf="invoice.DATA_PROCESSAMENTO">
                        <div class="row" *ngIf="invoice.DATA_PROCESSAMENTO">
                            <div class="col-xs-8 col-md-8">
                                Contrato atualizado em:
                            </div>
                            <div class="col-xs-4 col-md-4 data-pagamento text-right">
                                <strong>{{invoice.DATA_PROCESSAMENTO | date}}</strong>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-footer class="padding-up">
                        <div class="row">
                            <div class="col-xs-12 col-md-12 text-center">
<!--                                <button class="fill" (click)="buildCheckout()" mat-button mat-flat-button [disabled]="invoice.DATA_PAGAMENTO" color="primary">Pagar</button>-->
                                <button class="fill" mat-button mat-flat-button [matMenuTriggerFor]="menuBtnPagar" [disabled]="invoice.DATA_PAGAMENTO" color="primary">Pagar</button>
                                <mat-menu class="fill" #menuBtnPagar="matMenu">
                                    <button mat-menu-item (click)="proccessCheckout(constants.PAYMENT_METHOD.CREDIT_CARD)">Cartão de crédito</button>
                                    <button mat-menu-item (click)="proccessCheckout(constants.PAYMENT_METHOD.PIX)">Pix</button>
                                    <button mat-menu-item (click)="showBoleto()" *ngIf="invoice.CODIGO_BOLETO">Boleto</button>
                                    <button mat-menu-item (click)="copyCodigoBarrasBoleto()" *ngIf="invoice.CODIGO_BOLETO">Copiar código de barras do boleto</button>
                                </mat-menu>
                            </div>
                        </div>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
    </mat-card-content>
</mat-card>
