import { OpenedEntriesService } from './opened-entries.service';
import { Router } from '@angular/router';
import { OpenedEntries } from '../models/OpenedEntries';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import * as math from 'mathjs';

@Component({
  selector: 'app-opened-entries',
  templateUrl: './opened-entries.component.html',
  styleUrls: ['./opened-entries.component.css']
})

export class OpenedEntriesComponent implements OnInit {
  public openedEntriesArr: OpenedEntries = {} as OpenedEntries;
  public dataSourceOpenedEntries: MatTableDataSource<OpenedEntries> = new MatTableDataSource<OpenedEntries>();
  public displayedColumnsOpenedEntries: string[];
  public displayedColumns: string[] = [];
  public selectedEntries: number[] = [];
  public codeUser = localStorage.getItem('CODIGO_CADASTRO_GERAL');

  constructor(
    private service: OpenedEntriesService,
    public route: Router, ) {
      this.displayedColumnsOpenedEntries = [
        'vazio_inicio',
        'select',
        'CODIGO_BOLETO',
        // 'CODIGO_COMPLETO_LANCAMENTO',
        'DESCRICAO_LANCAMENTO',
        'DATA_EMISSAO',
        'DATA_VENCIMENTO',
        'VALOR_PREVISAO',
        'DIAS_ATRASO',
        'vazio'
      ];
    }

    private addLancamento(codigoLancamento: number): void {
      const exists = this.selectedEntries.indexOf(codigoLancamento);
      if (exists < 0){
        this.selectedEntries.push(codigoLancamento);
      }
    }

    private removeLancamento(codigoLancamento: number): void {
      const index = this.selectedEntries.indexOf(codigoLancamento);
      if (index > -1){
        this.selectedEntries.splice(index, 1);
      }
    }

  selecionarTodosOsLancamentos(): void {
    const checkedElements = document.getElementsByClassName('check-entry');
    const checkHeadElement = document.getElementById('check-head');
    const x = checkHeadElement as HTMLInputElement;
    let checkeds = 0;

    if (this.isAllSelected()) {
      for (let i = 0; i < checkedElements.length; i++) {
        const item = checkedElements[i] as HTMLInputElement;
        item.checked = false;
        // this.removeLancamento(item.dataset.codigo);
      }
      x.checked = false;
      this.getTotal();
      return;
    }

    if (checkedElements.length > 0) {
      for (let i = 0; i < checkedElements.length; i++) {
        const item = checkedElements[i] as HTMLInputElement;
        item.checked = true;
        checkeds = checkeds + 1;
        // this.addLancamento(item.dataset.codigo);
      }
      x.checked = true;
    }
    this.getTotal();
  }

  selecionarLancamento(codigoLancamento: number): void {
    let codigoBoleto: number = 0;
    let dataVencimentoBoleto: Date = new Date();
    for (let i = 0; i < this.dataSourceOpenedEntries.data.length; i++) {
      if (this.dataSourceOpenedEntries.data[i].CODIGO_LANCAMENTO === codigoLancamento) {
        dataVencimentoBoleto = this.dataSourceOpenedEntries.data[i].DATA_VENCIMENTO;
        codigoBoleto = this.dataSourceOpenedEntries.data[i].CODIGO_BOLETO;
      }
    }
    for (let i = 0; i < this.dataSourceOpenedEntries.data.length; i++) {
      if ( this.dataSourceOpenedEntries.data[i].CODIGO_LANCAMENTO !== codigoLancamento) {
        const el = document.getElementById(this.dataSourceOpenedEntries.data[i].CODIGO_LANCAMENTO.toString()) as HTMLInputElement;
        if ( this.dataSourceOpenedEntries.data[i].CODIGO_BOLETO === 0) {
          if ( this.dataSourceOpenedEntries.data[i].DATA_VENCIMENTO === dataVencimentoBoleto ) {
            this.marcarCheckbox(el);
          }
        } else if (
          (this.dataSourceOpenedEntries.data[i].CODIGO_BOLETO === codigoBoleto)
        ) {
          this.marcarCheckbox(el);
        }
      }
    }
    this.getTotal();
    const checkHeadElement = document.getElementById('check-head');
    const x = checkHeadElement as HTMLInputElement;
    if (this.isAllSelected()) {
      x.checked = true;
    }
    if (!this.hasValue() && !this.isAllSelected()) {
      x.checked = false;
    }
    if (this.hasValue() && !this.isAllSelected()) {
      x.indeterminate = true;
    }
  }

  marcarCheckbox(element: HTMLInputElement): void {
    element.checked = !element.checked;
  }

  hasValue(): boolean {
    const numSelected = document.getElementsByClassName('check-entry');
    let numCheckeds = 0;

    if (numSelected.length > 0) {
      for (let i = 0; i < numSelected.length; i++) {
        const item = numSelected[i] as HTMLInputElement;
        if (item.checked) {
          numCheckeds = numCheckeds + 1;
        }
      }
    }

    const numRows = this.dataSourceOpenedEntries?.data?.length;
    return (numCheckeds !== numRows) && numCheckeds > 0;
  }

  isAllSelected(): boolean {
    const numSelected = document.getElementsByClassName('check-entry');
    let numCheckeds = 0;

    if (numSelected.length > 0) {
      for (let i = 0; i < numSelected.length; i++) {
        const item = numSelected[i] as HTMLInputElement;
        if (item.checked) {
          numCheckeds = numCheckeds + 1;
        }
      }
    }

    const numRows = this.dataSourceOpenedEntries.data.length;
    return numCheckeds === numRows;
  }

  getTotal(): void {
    const elements = document.getElementsByClassName('check-entry');
    let total = 0;

    for (let i = 0; i < elements.length; i++) {
      const item = elements[i] as HTMLInputElement;
      if (item.checked) {
        for (let j = 0; j < this.dataSourceOpenedEntries.data.length; j++) {
          if (parseInt(item.id, 10) === this.dataSourceOpenedEntries.data[j].CODIGO_LANCAMENTO) {
            // total = (total + <number>this.dataSourceOpenedEntries.contracts[j].VALOR_PREVISAO)
            total = math.chain(total).add(this.dataSourceOpenedEntries.data[j].VALOR_PREVISAO as number).done();
          }
        }
      }
    }
    // document.getElementById('total').innerText = total.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
  }

  ngOnInit(): void {
    this.service.showSpinner();
    this.service.getOpenedEntriesList(this.codeUser ?? "").subscribe((openedEntries) => {
      this.dataSourceOpenedEntries = new MatTableDataSource<OpenedEntries>(openedEntries as any);
      this.service.hideSpinner();
    });
  }

  getSelected(): number[] {
    const inputs = document.getElementsByTagName('input');
    const ret: number[] = [];
    for (let i = 0; i < inputs.length; i++){
      const x : HTMLInputElement | null = inputs.item(i);
      if (x?.checked){
        if (Number.parseInt(x.value, 10)){
          ret.push(Number.parseInt(x.value, 10))
          // ret.push(x.value);
        }
      }
    }
    return ret;
  }

  payCreditCard(): void{
    this.service.showSpinner();
    const list = this.getSelected();
    this.service.createFaturaSelected(list).subscribe((res) => {
      this.service.hideSpinner();
      this.route.navigate(['principal/pagamento-cartao/' + res.CODIGO_FATURA]);
    }, (err) => {
      console.error(err);
      this.service.hideSpinner();
      this.service.toasterError(err.error, 'Erro ao gerar faturamento');
    });
  }
}
