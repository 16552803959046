<mat-card *ngIf="showComponent">
   <mat-card-title>Meus débitos em aberto</mat-card-title>
      <mat-card-content *ngIf="contracts.length > 0">
         <mat-accordion class="example-headers-align" multi>
            <div class="row">
               <div class="col-12">
                  <mat-expansion-panel *ngFor="let contract of contracts" [expanded]="true">
                     <mat-expansion-panel-header>
                        <mat-panel-title>Contrato {{contract.GEN_CONTRATO}} (Unidade {{contract.DESCRICAO_LOJA}} - Box {{contract.DESCRICAO}})</mat-panel-title>
                        <mat-panel-description *ngIf="contract.INADIMPLENCIA">(Contrato em atraso)</mat-panel-description>
                     </mat-expansion-panel-header>

                     <table mat-table [dataSource]="contract.MOVIMENTACOES_ABERTO" class="mat-elevation-z8">
                        <ng-container matColumnDef="SELETOR">
                           <th mat-header-cell *matHeaderCellDef></th>
                           <td mat-cell *matCellDef="let element">
<!--                              <mat-checkbox [value]="element.GEN_BOX_MOVIMENTACAO" formControlName="GEN_BOX_MOVIMENTACAO" (click)="appendMovement(element.GEN_BOX_MOVIMENTACAO, element.VALOR_TOTAL)" data-codigo="{{element.GEN_BOX_MOVIMENTACAO}}" color="primary"></mat-checkbox>-->
                           </td>
                        </ng-container>
                        <ng-container matColumnDef="MES">
                           <th mat-header-cell *matHeaderCellDef>Mês/Ano <small>(Referência)</small></th>
                           <td mat-cell *matCellDef="let element"> {{element.MES.toString().padStart(2, "0")}}/{{element.ANO}} </td>
                        </ng-container>
                        <ng-container matColumnDef="VALOR">
                           <th mat-header-cell *matHeaderCellDef>Aluguel do box </th>
                           <td mat-cell *matCellDef="let element"> {{element.VALOR - element.VALOR_DESCONTO | currency: 'BRL'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="VENCIMENTO">
                           <th mat-header-cell *matHeaderCellDef>Vencimento </th>
                           <td mat-cell *matCellDef="let element"> {{element.VENCIMENTO | date}} </td>
                        </ng-container>
                        <ng-container matColumnDef="VALOR_SEGURO">
                           <th mat-header-cell *matHeaderCellDef>Seguro </th>
                           <td mat-cell *matCellDef="let element"> {{element.VALOR_SEGURO | currency: 'BRL'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="VALOR_OUTROS">
                           <th mat-header-cell *matHeaderCellDef>Outros </th>
                           <td mat-cell *matCellDef="let element"> {{element.VALOR_OUTROS | currency: 'BRL'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="VALOR_TOTAL">
                           <th mat-header-cell *matHeaderCellDef>Valor total </th>
                           <td mat-cell *matCellDef="let element"> <strong>{{element.VALOR_TOTAL | currency: 'BRL'}}</strong> </td>
<!--                           <td mat-footer-cell *matFooterCellDef> {{selectedValue | currency: 'BRL'}} </td>-->
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                     </table>
                  </mat-expansion-panel>
               </div>
            </div>
            <div class="row">
               <div class="col-12">
                  <button (click)="createInvoice()" class="botao" mat-raised-button color="primary" *ngIf="total">Continuar com o pagamento de {{total | currency: 'BRL'}} + taxas</button>
               </div>
            </div>
         </mat-accordion>
      </mat-card-content>
   <mat-card-content *ngIf="contracts.length == 0">
      <div class="row">
         <div class="col-12">
            <div class="alert alert-success">
               <div class="text-center">
                  <strong>Parabéns! Você está em dia conosco.</strong>
               </div>
            </div>
         </div>
      </div>
   </mat-card-content>
</mat-card>

