<h1 mat-dialog-title>Termos de uso para pagamento recorrente</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12" [innerHTML]="termos | safeHtml"></div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Fechar</button>
</div>

