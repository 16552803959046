import { BaseServiceService } from './../base-service.service';
import { Injectable } from '@angular/core';
import { BoxAccessUsers } from '../models/BoxAccessUsers';
import {Observable} from 'rxjs';
import { GeneralRegister } from '../models/GeneralRegister';

@Injectable({
  providedIn: 'root'
})
export class BoxAccessUserService extends BaseServiceService {
  public setBoxAccessUser(
    id: string, contrato: string, cpf: string, nome: string, liberado: boolean, observacao: string, nomeDisplay: string, codigoAcesso: string, codigoCadastroGeral: string
  ): Observable<any> {
    return this.post(this.getUrl('client_area/box_access_user'), {
      genContratoControleAcesso: id,
      genContrato: contrato,
      cpf: cpf,
      nome: nome,
      acessoLiberado: liberado ? 'S' : 'N',
      observacao: observacao,
      nomeDisplay: nomeDisplay,
      codigoAcesso: codigoAcesso,
      codigoCadastroGeral: codigoCadastroGeral
    });
  }

  public deleteBoxAccessUser(id: number): Observable<any>{
    return this.delete(this.getUrl('client_area/box_access_user/' + id));
  }

  public getDataBoxAccessUser(id: string): Observable<BoxAccessUsers> {
    return this.get('client_area/box_access_user/' + id);
  }

  public getCadastroGeral(document: string): Observable<GeneralRegister> {
    return this.get('client_area/search_by_document/' + document);
  }
}
