import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {Dashboard} from '../models/Dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseServiceService{
  getStatistics(): Observable<Dashboard> {
    return this.get('client_area/dashboard');
  }
}
